<template>
    <front-layout>
        <div class="page__top-line d-flex flex-column justify-center align-center fill-height pa-5 pb-16">
            <div v-if="!$auth.check()">
                <v-card flat min-width="300" max-width="500">
                    <v-card-title>Please login to confirm your email address</v-card-title>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :error-messages="errors.email"
                                        label="Email"
                                        v-model="email"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :error-messages="errors.password"
                                        type="password"
                                        label="Password"
                                        v-model="password"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <v-card-actions class="text-center justify-space-around">
                        <div>
                            <div>
                                <v-col>
                                    <v-btn block class="success" @click="handleLogin">Login</v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn block>
                                        <v-icon left>mdi-google</v-icon>
                                        <a href="/social/redirect/google">Google</a>
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn block>
                                        <v-icon left>mdi-facebook</v-icon>
                                        <a href="/social/redirect/facebook">Facebook</a>
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                    </v-card-actions>
                </v-card>
            </div>
            <div v-else-if="readyForVerification">
                <v-card flat min-width="300" max-width="500">
                    <v-card-text>
                        <v-row>
                            <v-col
                                class="text-subtitle-1 text-center"
                                cols="12"
                            >
                                Verifying, please wait
                            </v-col>
                            <v-col>
                                <v-progress-linear/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
            <div v-else>
                <v-card flat min-width="300" max-width="500">
                    <v-card-title>Please click the link in the confirmation email</v-card-title>
                    <v-card-text>
                        <v-btn @click="resendEmail">
                            Resend confirmation email
                        </v-btn>
                    </v-card-text>
                </v-card>
            </div>

        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import {mapMutations} from "vuex";

export default {
    name: "VerifyEmail",
    components: {FrontLayout},
    data() {
        return {
            id: null,
            hash: null,
            user: {},
            email: null,
            password: null,
            errors: [],
        }
    },
    computed: {
        readyForVerification() {
            return this.id && this.hash && this.user.id
        }
    },
    mounted() {
        this.user = this.$auth.user() ?? {}

        if (this.user['email_verified_at']) {
            this.$router.push(this.$loginRedirectRoute(this.user.roles))
            return
        }

        this.id = this.$route.params.id
        this.hash = this.$route.params.hash

        if (this.readyForVerification) {
            this.verifyEmail()
        }
    },
    methods: {
        async verifyEmail() {
            await window.axios.get(`/api/verify-email/${this.id}/${this.hash}`)
            this.user = (await this.$auth.fetch())['data']['data']
            this.$router.push(this.$loginRedirectRoute(this.user.roles))
        },

        async resendEmail() {
            await window.axios.get('/api/resend-confirmation-email')
            this.showSnackBar({
                color: 'success',
                timeout: 3000,
                text: 'A new confirmation email has been sent!'
            })
        },

        async handleLogin() {
            const {email, password} = this;
            this.$auth
                .login({data: {email, password}, redirect: null})
                .then(res => {
                    this.user = res.data.data
                    this.verifyEmail()
                })
                .catch((error) => this.errors = error.response.data.errors)
        },

        ...mapMutations(['showSnackBar']),
    }
}
</script>

<style scoped>

</style>
